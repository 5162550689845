import { Constantes } from '../Constantes';
import moment from 'moment'

export const insertDatos = async(email, valoracion, check_values, comentario) => {
 
    console.log('Guarda')

    let now = moment();
    let fecha = now.format('DD/MM/YY');



    const bodyResultado = {
      email,
      calificacion: valoracion,
      check_values,
      comentario,
      fecha
    }

    const cargaUtil = JSON.stringify(bodyResultado);

    const respuesta = await fetch(`${Constantes.RUTA_API}/ingresar_calificacion.php`, {
      method: "POST",
      body: cargaUtil,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const exitoso = await respuesta.json();

    if (exitoso) {
      const resp = {
        ok: exitoso,
        calificacion: valoracion
      }
      return resp
    }else {
      const resp = {
        ok: exitoso
      }
      return resp
    }




}