import React from 'react'
import {  useLocation } from 'react-router-dom'
import { Encuesta } from './components/ui/Encuesta'
import { EncuestaSinEmail } from './components/ui/EncuestaSinEmail'
import { Header } from './components/ui/Header'

export const EncuestaApp = () => {


  let location = useLocation();
  if (location.search === '') {
    return (
      <EncuestaSinEmail />
    )
  }else {
    const email = location.search.slice(1)
    return (    
      <>
              <Header />
              <Encuesta  email={email} />
      </>
    )
  }
}
