import { Constantes } from '../Constantes';

export const consultarEmail = async(email) => {

  const bodyEmail = {
    email
  }
  const cargaUtil = JSON.stringify(bodyEmail);
  const resp = await fetch(`${Constantes.RUTA_API}/obtener_email.php`, {
    method: "POST",
    body: cargaUtil,
    headers: {
      'Content-Type': 'application/json'
    }
    })
    
    const response = await resp.json();

    if (typeof response === 'object') {
      return true   
    } else {
      return false
    }
}